import { createI18n } from 'vue-i18n';
import es from '@/locales/es';
import en from '@/locales/en';
import pt from '@/locales/pt';

const messages = {
  es,
  en,
  pt,
};

let defaultLocale;
if (navigator.language.length > 2) {
  defaultLocale = 'es';
} else if (messages[navigator.language]) {
  defaultLocale = navigator.language;
} else {
  defaultLocale = 'es';
}
window?.localStorage?.setItem(
  'locale',
  window?.localStorage?.locale || defaultLocale,
);

export const i18n = createI18n({
  legacy: false, // Vuetify does not support the legacy mode of vue-i18n
  locale: window?.localStorage?.locale,
  fallbackLocale: defaultLocale,
  messages,
});
