import { formatDateFromSF } from '@/helpers';

export function formatVoting(item) {
  return {
    ...item,
    nombre: `${item.Iniciativa__r?.Name} (${item.Name})`,
    subject: item.Iniciativa__r?.Tema__c,
    date: formatDateFromSF(item.Fecha__c),
    accountName: item.Establecimiento__r?.Name,
  };
}

export function prepareVotings(votings = {}) {
  for (let key in votings) {
    votings[key] = formatVoting(votings[key]);
  }
  return votings;
}
