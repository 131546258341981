import axios, { Axios } from 'axios';

export const retries = { attempts: 0, count: 0 };

export const baseApi = new Axios({
  baseURL: import.meta.env.VITE_BASE_API_URL,
});

export const setBaseApiToken = (token) => {
  const headers = {
    ...axios.headers,
    Authorization: token,
  };
  baseApi.defaults.headers = {
    common: headers,
  };
  return headers;
};

export const setResponseInterceptor = (
  responseInterceptor = () => {},
  errorInterceptor = () => {},
) => {
  baseApi.interceptors.response.use(
    function (response) {
      return responseInterceptor(response);
    },
    function (error) {
      return errorInterceptor(error);
    },
  );
};

export const getRole = async () => {
  return await baseApi.get('/role');
};

// export const ingresar = async (
//   payload = {
//     firstName: '',
//     lastName: '',
//     sex: '',
//     birthdate: '',
//     state: '',
//     city: '',
//     phone: '',
//     email: '',
//     photo: '',
//     key: '',
//   },
// ) => {
//   const formData = new FormData();
//   formData.append('usuario', JSON.stringify(payload));
//   return await baseApi.post('/ingresar', formData);
// };

export const getEstablecimientos = async () => {
  return await baseApi.get('/establecimientos');
};

export const politicos = async (
  filtros = {
    Establecimiento__c: '',
    _Fecha: '',
  },
) => {
  let formData = new FormData();
  formData.append('filtros', JSON.stringify(filtros));
  return await baseApi.post('/politicos', formData);
};

export const proyectos = async (
  filtros = {
    Estado__c: '',
    RecordTypeId: '',
    Posicion__c: '',
    Tema__c: '',
    Tipo__c: '',
  },
) => {
  let formData = new FormData();
  formData.append('filtros', JSON.stringify(filtros));
  return await baseApi.post('/proyectos', formData);
};

export const votaciones = async (
  filtros = {
    RecordTypeId: '',
    Establecimiento__c: '',
    Tipo__c: '',
    Estatus__c: '',
  },
) => {
  let formData = new FormData();
  formData.append('filtros', JSON.stringify(filtros));
  return await baseApi.post('/votaciones', formData);
};

export const publicaciones = async (
  filtros = {
    Estado__c: '',
    Tema__c: '',
    Medio__c: '',
    Estatus__c: '',
  },
) => {
  let formData = new FormData();
  formData.append('filtros', JSON.stringify(filtros));
  return await baseApi.post('/publicaciones', formData);
};

export const establecimiento = async (id) => {
  return await baseApi.get('/establecimiento', { params: { id } });
};

export const politico = async (id) => {
  return await baseApi.get('/politico', { params: { id } });
};

export const proyecto = async (id) => {
  return await baseApi.get('/proyecto', { params: { id } });
};

export const votacion = async (id) => {
  return await baseApi.get('/votacion', { params: { id } });
};

export const publicacion = async (id) => {
  return await baseApi.get('/publicacion', { params: { id } });
};

export const parseData = (responseData) => {
  let data = { table: {}, form: {} };
  try {
    data = JSON.parse(responseData || '{}');
  } catch (e) {
    console.error(e);
  }
  return data;
};

export const crearSolicitud = async (
  payload = { recordId: '', mensaje: '' },
) => {
  const formData = new FormData();
  formData.append('solicitud', JSON.stringify(payload));
  return await baseApi.post('/crear_solicitud', formData);
};
