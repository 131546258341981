export default Object.freeze({
  alert: {
    signIn: 'Preencha o seguinte formulário para ter acesso à plataforma.',
    insertCode:
      'Por favor, insira abaixo o código para verificar seu telefone.',
    chooseState: 'Por favor, selecione um estado.',
  },
  text: {
    firstName: 'Nome(s)',
    lastName: 'Sobrenome(s)',
    sex: 'Sexo',
    birthdate: 'Data de nascimento',
    state: 'Estado',
    city: 'Municipio',
    phone: 'Telefone',
    code: 'Código',
    email: 'E-mail',
    theme: 'Tema',
    locale: 'Idioma',
    dateFormat: 'DD/MM/AAAA',
    required: 'Por favor, preencha esse campo.',
    isNumber: 'Use apenas números.',
    isPhoneNumber: 'Os números de telefone devem ser como +5255XXXXXXXX',
    isDate: 'Por favor, preencha com uma data válida.',
  },
  select: {
    sex: {
      male: 'Masculino',
      female: 'Feminino',
    },
    theme: {
      light: 'Claro',
      dark: 'Escuro',
    },
  },
  button: {
    signIn: 'Entrar',
    signOut: 'Sair',
    language: 'Português',
    confirm: 'Confirmar',
    update: 'Atualizar',
  },
});
