import { defineStore } from 'pinia';
import {
  getRole,
  // ingresar,
} from '@/services/base-api';
import { useAutheStore } from '@/stores/authentication.js';

const unFormatBirthday = (day) => {
  const dmy = day.split('-');
  return `${dmy[2]}/${dmy[1]}/${dmy[0]}`;
};

const actions = {
  async setData(data) {
    const autheStore = useAutheStore();
    if (data.role) {
      this.role = data.role;
    }
    if (data.contacto) {
      for (let key in data.contacto) {
        if (key === 'birthdate') {
          data.contacto[key] = unFormatBirthday(data.contacto[key]);
        }
        if (key !== 'photo')
          autheStore.user[key] = data.contacto[key] || autheStore.user[key];
      }
    }
    if (data.states) {
      data.states.sort((a, b) => a.value.localeCompare(b.value));
      this.states = data.states.map((choice) => ({
        ...choice,
        title: choice.name,
      }));
    }
  },
  async getRole() {
    this.loadingRole = true;
    const response = await getRole();
    if (response?.data) {
      let data = {};
      try {
        data = JSON.parse(response.data);
      } catch (e) {
        console.error(e);
        data = {};
      }
      await this.setData(data);
    }
    this.loadingRole = false;
  },
  // async ingresar(user) {
  // const autheStore = useAutheStore();
  //   this.loadingSignIn = true;
  //   const response = await ingresar(user || autheStore.user);
  //   let data;
  //   if (response.data) {
  //     try {
  //       data = JSON.parse(response.data);
  //       if (!data) {
  //         return;
  //       }
  //       if (data.ingresado) {
  //         await this.setData(data);
  //       }
  //     } catch (e) {
  //       console.error(e);
  //     }
  //   } else {
  //     console.error({ code: 'Could not sign in' });
  //   }
  //   this.loadingSignIn = false;
  //   return data;
  // },
};

export const useAuthoStore = defineStore('autho', {
  state: () => ({
    role: undefined,
    states: [],
    loadingRole: false,
    loadingSignIn: false,
  }),
  getters: {},
  actions,
});
