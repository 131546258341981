import { formatDateFromSF } from '@/helpers';

export function formatProject(item) {
  return {
    ...item,
    date: formatDateFromSF(
      item.Fecha_de_presentacion__c || item.Fecha_de_resolucion__c,
    ),
    accountName: item.Establecimiento__r?.Name,
  };
}

export function prepareProjects(projects = {}) {
  for (let key in projects) {
    projects[key] = formatProject(projects[key]);
  }
  return projects;
}
