import {
  getAuth,
  useDeviceLanguage,
  signInWithRedirect,
  getRedirectResult,
  GoogleAuthProvider,
  RecaptchaVerifier,
  linkWithPhoneNumber,
  signOut,
} from 'firebase/auth';

export function authSignIn() {
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  provider.setCustomParameters({
    prompt: 'select_account',
    access_type: 'offline',
    // redirect_uri: 'http://127.0.0.1:8080',
  });
  const scopes = [
    'https://www.googleapis.com/auth/plus.login',
    // 'https://www.googleapis.com/auth/user.addresses.read',
    // 'https://www.googleapis.com/auth/user.birthdate.read',
    // 'https://www.googleapis.com/auth/user.gender.read',
    'https://www.googleapis.com/auth/user.phonenumbers.read',
    'https://www.googleapis.com/auth/userinfo.email',
    'https://www.googleapis.com/auth/userinfo.profile',
  ];
  for (let scope of scopes) {
    provider.addScope(scope);
  }
  signInWithRedirect(auth, provider);
}

export async function authLinkWithPhoneNumber(
  phoneNumber,
  callback = () => {},
  expiredCallback = () => {},
) {
  const auth = getAuth();
  useDeviceLanguage(auth);
  window.recaptchaVerifier = new RecaptchaVerifier(
    auth,
    'recaptcha-container',
    {
      size: 'normal',
      callback,
      'expired-callback': expiredCallback,
    },
  );
  try {
    const confirmationResult = await linkWithPhoneNumber(
      auth.currentUser,
      phoneNumber,
      window.recaptchaVerifier,
    );
    // SMS sent. Prompt user to type the code from the message, then sign the
    // user in with confirmationResult.confirm(code).
    return confirmationResult;
  } catch (error) {
    console.error(error);
    return;
  }
}

export async function authGetResult() {
  const auth = getAuth();
  const result = await getRedirectResult(auth);
  let user = auth.currentUser || result?.user;
  if (user) {
    const displayName = user.displayName?.split?.(' ') || [];
    user = {
      firstName: displayName[0],
      lastName: 1 in displayName ? displayName.slice(1) : [],
      email: user.email,
      phone: user.phoneNumber,
      photo: user.photoURL,
      key: user.email.replace(/@.*/, '').replace(/(\.|#|\$|\[|\])/g, '_'),
    };
  }
  return user;
}

export async function authGetToken(forceRefresh = false) {
  const auth = getAuth();
  const idToken = await auth.currentUser?.getIdToken(forceRefresh);
  return idToken ? `Bearer ${idToken}` : null;
}

export function authSignOut() {
  const auth = getAuth();
  return signOut(auth);
}
