import { defineStore } from 'pinia';
import {
  getEstablecimientos,
  proyectos,
  politicos,
  votaciones,
  publicaciones,
  establecimiento,
  politico,
  proyecto,
  votacion,
  publicacion,
  parseData,
  crearSolicitud,
} from '@/services/base-api';
import {
  formatPolitician,
  preparePoliticians,
} from '@/controllers/politician.js';
import { prepareProjects } from '@/controllers/project.js';
import { prepareVotings } from '@/controllers/voting.js';
import { preparePublications } from '@/controllers/publicacion.js';
import { formatDateFromSF } from '@/helpers';

const actions = {
  async getAccounts() {
    this.loadingAccounts = true;
    let data = { table: {}, form: {} };
    if (!this.table.accounts) {
      let response;
      try {
        response = await getEstablecimientos();
      } catch (e) {
        console.error(e);
      }
      if (response?.data) {
        try {
          data = JSON.parse(response.data);
        } catch (e) {
          console.error(e);
        }
        this.table.accounts = Object.fromEntries(
          Object.values(data.table.establecimientos)
            .sort((a, b) => a.Name.localeCompare(b.Name))
            .map((account) => [account.Id, account]),
        );
        this.table.comissions = data.table.comisiones;
        this.choices = data.form;
      }
    }
    this.loadingAccounts = false;
    return data;
  },
  async getPoliticians(filters = {}) {
    this.loadingPoliticians = true;
    const response = await politicos(filters);
    if (response.data) {
      const data = parseData(response.data);
      this.table.politicians = preparePoliticians(data.table);
      this.filters.politicians = JSON.stringify(filters);
    }
    this.loadingPoliticians = false;
  },
  async getProjects(filters = {}) {
    this.loadingProjects = true;
    const response = await proyectos(filters);
    if (response.data) {
      const data = parseData(response.data);
      this.table.projects = prepareProjects(data.table.proyectos);
      this.filters.projects = JSON.stringify(filters);
    }
    this.loadingProjects = false;
  },
  async getVotings(filters = {}) {
    this.loadingVotings = true;
    const response = await votaciones(filters);
    if (response.data) {
      const data = parseData(response.data);
      this.table.votings = prepareVotings(data.table.votaciones);
      this.filters.votings = JSON.stringify(filters);
    }
    this.loadingVotings = false;
  },
  async getPublications(filters = {}) {
    this.loadingPublications = true;
    const response = await publicaciones(filters);
    if (response.data) {
      const data = parseData(response.data);
      this.table.publications = preparePublications(data.table.publicaciones);
      this.filters.publications = JSON.stringify(filters);
    }
    this.loadingPublications = false;
  },
  async getAccount(id = '') {
    this.loadingAccount = true;
    const response = await establecimiento(id);
    if (response.data) {
      const data = parseData(response.data);
      data?.establecimiento && this.prepareAccount(data);
    }
    this.loadingAccount = false;
  },
  prepareAccount(account) {
    const index = account.establecimiento.Id;
    this.rows.accounts[index] = {
      ...(this.accounts[index] || {}),
      ...(this.rows.accounts[index] || {}),
      ...account,
    };
  },
  async getPolitician(id = '') {
    this.loadingPolitician = true;
    const response = await politico(id);
    if (response.data) {
      const data = parseData(response.data);
      data?.contacto && this.preparePolitician(data);
    }
    this.loadingPolitician = false;
  },
  preparePolitician(politician) {
    const index = politician.contacto.Id;
    this.rows.politicians[index] = {
      ...(this.politicians[index] || {}),
      ...(this.rows.politicians[index] || {}),
      ...politician,
      cadeira: {
        ...(politician.cadeira || {}),
        ...formatPolitician(politician.cadeira),
      },
    };
  },
  async getProject(id = '') {
    this.loadingProject = true;
    const response = await proyecto(id);
    if (response.data) {
      const data = parseData(response.data);
      data?.proyecto && this.prepareProject(data);
    }
    this.loadingProject = false;
  },
  prepareProject(project) {
    const id = project.proyecto.Id;
    this.rows.projects[id] = {
      ...(this.projects[id] || {}),
      ...(this.rows.projects[id] || {}),
      ...project,
    };
  },
  async getVoting(id = '') {
    this.loadingVoting = true;
    const response = await votacion(id);
    if (response.data) {
      const data = parseData(response.data);
      if (data?.votacion) {
        this.prepareVoting(data.votacion);
        this.prepareVotes(data.votacion.Id, data.votos);
      }
    }
    this.loadingVoting = false;
  },
  prepareVoting(voting) {
    this.rows.votings[voting.Id] = {
      ...(this.votings[voting.Id] || {}),
      ...(this.rows.votings[voting.Id] || {}),
      ...voting,
      date: formatDateFromSF(voting.Fecha__c),
    };
  },
  prepareVotes(votingId, votes) {
    let results = {};
    this.rows.votes[votingId] = {
      values: votes.map((row) => {
        if (row.titular) {
          row.titular_Politico__r_Id = row.titular.Politico__c;
          row.titular_Politico__r_Name = row.titular.Politico__r?.Name;
          row.titular_Partido__r_Name = row.titular.Partido__r?.Name;
        }
        if (row.suplente) {
          row.suplente_Politico__r_Id = row.suplente.Politico__c;
          row.suplente_Politico__r_Name = row.suplente.Politico__r?.Name;
          row.suplente_Partido__r_Name = row.suplente.Partido__r?.Name;
        }
        if (row.voto) {
          row.vote = row.voto.Tipo__c;
          if (!results[row.vote]) {
            results[row.vote] = 0;
          }
          results[row.vote]++;
          row.type = row.voto.SubTipo__c;
          row.id = row.voto.Id;
        } else {
          row.vote = null;
          row.type = null;
          row.id = null;
        }
        row.isSubstitute = row.hechoPorSuplente;
        if (row.isSubstitute) {
          row.politicianName = row.suplente_Politico__r_Name;
          row.partyName = row.suplente_Partido__r_Name;
          row.curriculumId = row.suplente.Id;
          row.politicianId = row.suplente_Politico__r_Id;
        } else {
          row.politicianName = row.titular_Politico__r_Name;
          row.partyName = row.titular_Partido__r_Name;
          row.curriculumId = row.titular?.Id;
          row.politicianId = row.titular_Politico__r_Id;
        }
        row.politicos = [
          { title: row.titular_Politico__r_Name, value: false },
          { title: row.suplente_Politico__r_Name, value: true },
        ];
        row.cadeira_Id = row.cadeira.Id;
        row.crear = 'votos';
        row.votacionId = votingId;
        row.rowId = row.cadeira.Mandato__c;
        return row;
      }),
    };
  },
  async getPublication(id = '') {
    this.loadingPublication = true;
    const response = await publicacion(id);
    if (response.data) {
      const data = parseData(response.data);
      data?.publicacion && this.preparePublication(data.publicacion);
    }
    this.loadingPublication = false;
  },
  preparePublication(publication) {
    this.rows.publications[publication.Id] = {
      ...(this.publications[publication.Id] || {}),
      ...(this.rows.publications[publication.Id] || {}),
      ...publication,
      date: formatDateFromSF(publication.Fecha__c),
    };
  },
  async createRequest(payload = {}) {
    this.loadingCreation = true;
    let data = false;
    const response = await crearSolicitud(payload);
    if (response.data) {
      data = parseData(response.data);
    }
    this.loadingCreation = false;
    return data;
  },
};

export const useDbStore = defineStore('db', {
  state: () => ({
    table: {
      accounts: null,
      comissions: null,
      politicians: null,
      projects: null,
      votings: null,
      publications: null,
    },
    rows: {
      accounts: {},
      comissions: {},
      politicians: {},
      projects: {},
      votings: {},
      votes: {},
      publications: {},
    },
    filters: {
      politicians: null,
      projects: null,
      votings: null,
      publications: null,
    },
    choices: null,
    loadingAccounts: false,
    loadingProjects: false,
    loadingPoliticians: false,
    loadingVotings: false,
    loadingPublications: false,
    loadingAccount: false,
    loadingPolitician: false,
    loadingProject: false,
    loadingVoting: false,
    loadingPublication: false,
    loadingCreation: false,
    loadingChoices: false,
  }),
  getters: {
    prevAccount: (context) => (Id) => context.accounts[Id] || {},
    prevPolitician: (context) => (Id) => context.politicians[Id] || {},
    prevProject: (context) => (Id) => context.projects[Id] || {},
    prevVoting: (context) => (Id) => context.votings[Id] || {},
    prevPublication: (context) => (Id) => context.publications[Id] || {},

    tableComissions: (context) => (account) => {
      const comissions = context.table.comissions || {};
      const tableComissions = Object.values(comissions).filter(
        (comission) => comission.Parent.Id === account.Id,
      );
      return tableComissions;
    },
    tableAccounts: (context) => Object.values(context.table.accounts || {}),
    accounts: (context) => context.table.accounts || {},
    politicians: (context) => context.table.politicians || {},
    projects: (context) => context.table.projects || {},
    votings: (context) => context.table.votings || {},
    publications: (context) => context.table.publications || {},

    account: (context) => (Id) => context.rows.accounts[Id] || {},
    politician: (context) => (Id) => context.rows.politicians[Id] || {},
    project: (context) => (Id) => context.rows.projects[Id] || {},
    voting: (context) => (Id) => context.rows.votings[Id] || {},
    votingVotes: (context) => (Id) => context.rows.votes[Id] || { values: [] },
    publication: (context) => (Id) => context.rows.publications[Id] || {},

    getAccountOrComission: (context) => (Id) =>
      context.table.accounts?.[Id] ||
      context.rows.accounts?.[Id] ||
      context.table.comissions?.[Id] ||
      context.rows.comissions?.[Id] || { Name: '' },

    choicesProjectTypes: (context) =>
      context.choices?.proyectos?.tiposDeRegistro || [],
    projectPositions: (context) => (projectType) => {
      const positions = context.choices?.proyectos?.posiciones || {};
      return projectType in positions ? positions[projectType] : [];
    },
    projectSubjects: (context) => context.choices?.proyectos?.temas || [],
    projectChoiceTypes: (context) => (projectType) => {
      const types = context.choices?.proyectos?.tipos || {};
      return projectType in types ? types[projectType] : [];
    },
    choicesVotingTypes: (context) =>
      context.choices?.votaciones?.tiposDeRegistro || [],
    votingChoiceTypes: (context) => context.choices?.votaciones?.tipos || [],
    votingStatus: (context) => context.choices?.votaciones?.estatus || [],
    publicationSubjects: (context) =>
      context.choices?.publicaciones?.temas || [],
    publicationMedia: (context) => context.choices?.publicaciones?.medios || [],
    publicationStatus: (context) =>
      context.choices?.publicaciones?.estatus || [],
    periodNumbers: (context) => context.choices?.periodos || [],
  },
  actions,
});
