export default Object.freeze({
  common: {
    subject: {
      Aborto: 'Aborto',
      Género: 'Género',
      Salud: 'Salud',
      Familia: 'Familia',
      Violencia: 'Violencia',
      Educación: 'Educación',
      Vida: 'Vida',
      Libertad: 'Libertad',
      Otros: 'Otros',
    },
  },
  project: {
    projectType: {
      iniciativa: 'Iniciativa',
      dictamen: 'Dictamen',
      sentencia: 'Sentencia',
    },
    position: {
      Registro: 'Registro',
      Comisiones: 'Comisiones',
      Pleno: 'Pleno',
      'En revisora': 'En revisora',
      Ejecutivo: 'Ejecutivo',
      Retirado: 'Retirado',
      Desechado: 'Desechado',
      'Caducidad legislativa': 'Caducidad legislativa',
      Publicado: 'Publicado',
      'En vigor': 'En vigor',
      Dictaminado: 'Dictaminado',
      'Sala/Pleno': 'Sala/Pleno',
      Resuelto: 'Resuelto',
    },
    type: {
      Abroga: 'Abroga',
      Deroga: 'Deroga',
      'Deroga y Reforma': 'Deroga y Reforma',
      Exhorto: 'Exhorto',
      'Nueva Ley': 'Nueva Ley',
      Reforma: 'Reforma',
      Reserva: 'Reserva',
      Solicitud: 'Solicitud',
      Otro: 'Otro',
      'Acción inconstitucionalidad': 'Acción inconstitucionalidad',
      'Amparo directo': 'Amparo directo',
      'Amparo directo en revisión': 'Amparo directo en revisión',
      'Amparo en revisión': 'Amparo en revisión',
      'Controversia constitucional': 'Controversia constitucional',
    },
  },
  publication: {
    media: {
      Fotografia: 'Fotografia',
      Periodico: 'Periodico',
      Radio: 'Radio',
      'Red Social': 'Red Social',
      Sitio: 'Sitio',
      TV: 'TV',
      Video: 'Video',
      Testimonio: 'Testimonio',
      'Propuesta de Gobierno': 'Propuesta de Gobierno',
      Otro: 'Otro',
    },
    status: {
      Original: 'Original',
      Cambiado: 'Cambiado',
      Excluido: 'Excluido',
    },
  },
  voting: {
    votingType: {
      discusion: 'Discusión',
      votacion: 'Votación',
      votacion_scjn: 'Votación (SCJN)',
    },
    status: {
      'En estudio': 'En estudio',
      Finalizado: 'Finalizado',
    },
    result: {
      Pendente: 'Pendente',
      'No aprobado': 'No aprobado',
      Aprobado: 'Aprobado',
    },
    direction: {
      Validez: 'Validez',
      Invalidez: 'Invalidez',
      Sobreseimiento: 'Sobreseimiento',
    },
    type: {
      Nominal: 'Nominal',
      Económica: 'Económica',
      'Por cédula': 'Por cédula',
      Provisional: 'Provisional',
    },
  },
  vote: {
    option: {
      'A favor': 'A favor',
      'En contra': 'En contra',
      Abstencion: 'Abstención',
      Ausente: 'Ausente',
      null: 'Sin información',
      undefined: 'Sin información',
      '': 'Sin información',
    },
  },
  label: {
    list: 'Listado',
    projects: 'Proyectos',
    dictamen: 'Dictamen',
    politicians: 'Políticos',
    politician: 'Político',
    votings: 'Votaciones',
    votes: 'Votos',
    vote: 'Voto',
    publications: 'Publicaciones',
    accounts: 'Establecimientos',
    account: 'Establecimiento',
    comissions: 'Comisiónes',
    comission: 'Comisión',
    plenos: 'Plenos',
    period: 'Periodo',
    filters: 'Filtros',
    projectType: 'Tipo de proyecto',
    votingType: 'Tipo de votación',
    alias: 'Alias',
    position: 'Posición',
    subject: 'Tema',
    subsubject: 'Subtema',
    type: 'Tipo',
    otherType: 'Otro tipo',
    source: 'Origen',
    party: 'Partido',
    electionType: 'Tipo de elección',
    electoralDistrict: 'Distrito electoral',
    iniciativas: 'Iniciativas',
    adherencias: 'Adherencias',
    votaciones: 'Votaciones',
    menciones: 'Menciones',
    comision: 'Comisión',
    comisiones: 'Comisiones',
    curricula: 'Curricula',
    licencias: 'Licencias',
    asistencias: 'Asistencias',
    generatedProjects: 'Proyectos Generados',
    inicialistas: 'Inicialistas',
    adherentes: 'Adherentes',
    datePresentation: 'Fecha de presentación',
    dateResolution: 'Fecha de resolución',
    media: 'Medio',
    status: 'Estatus',
    publicationTitle: 'Título de la Publicación',
    publicationDrive: 'Publicación (Drive)',
    date: 'Fecha',
    dateInicial: 'Fecha inicial',
    dateFinal: 'Fecha final',
    webSource: 'Fuente (web)',
    author_reporter: 'Autor/Reportero',
    mentioned: 'Contacto/Político mencionado',
    comments: 'Comentarios',
    description: 'Descripción',
    observations: 'Observaciones',
    details: 'Detalles',
    relations: 'Relaciones',
    relationships: 'Relaciones',
    rapporteur: 'Ponente',
    documents: 'Documentos',
    laws: 'Leyes',
    legislationAndLaws: 'Legislación y Leyes',
    inFavor: 'A favor',
    against: 'En contra',
    abstention: 'Abstención',
    absent: 'Ausente',
    votingText: 'Texto de la Votación (PDF)',
    votingDrive: 'Votación (Drive)',
    votingOficial: 'Oficial de SCJN',
    votingFinal: 'Sentencia final',
    projectDirection: 'Sentido del proyecto',
    summary: 'Sumario',
    isSubstitute: '¿Es suplente?',
    name: 'Nombre',
    project: 'Proyecto',
    result: 'Resultado',
    privacyAndTerms: 'Privacidad y condiciones',
    verifyPhone: 'Verificar teléfono',
    state: 'Estado',
    action: 'Acción',
    true: 'Si',
    false: 'No',
    plenary: 'PLENO',
    religiousInfo: 'Información religiosa',
    additionalInfo: 'Información complementaria',
    owner: 'Propietario',
    substitute: 'Suplente',
    sex: 'Sexo',
    firstName: 'Nome(s)',
    lastName: 'Apellido(s)',
    post: 'Cargo',
    mainPost: 'Cargo principal',
    birthdate: 'Fecha de nacimiento',
    age: 'Edad',
    kids: 'Cantidad de hijos',
    quotas: 'Por cuotas',
    hobby: 'Hobby/pasatiempo',
    otherHobby: 'Otro hobby',
    professed: 'Religión profesada',
    otherProfessed: 'Otra religión profesada',
    practiced: 'Religión practicada',
    otherPracticed: 'Otra religión practicada',
    parish: 'Parroquia',
    diocese: 'Diocesis',
    denomination: 'Denominación religiosa',
    site: 'Sitio',
    facebook: 'Facebook',
    instagram: 'Instagram',
    youtube: 'Youtube',
    twitter: 'Twitter',
    otherLink: 'Otro link',
    extension: 'Extension',
    workEmail: 'Correo de trabajo',
    otherEmail: 'Correo personal',
    curriculum: 'Curriculum (Link)',
    privateAddress: 'Dirección particular',
    zipCode: 'CP',
    country: 'Pais',
    town: 'Ciudad',
    colony: 'Colonia',
    street: 'Calle',
    externalNumber: 'Num exterior',
    internalNumber: 'Num interior',
    lot: 'Lote',
    block: 'Cuadra / Manzana',
    city: 'Municipio / Alcadia',
    phone: 'Teléfono',
    code: 'Código',
    email: 'E-mail',
    theme: 'Tema',
    locale: 'Idioma',
    search: 'Búsqueda',
    maritalStatus: 'Estado civil',
    seeMore: 'Ver más',
    seeLess: 'Ver menos',
    requestChanges: 'Solicitar ajustes',
    message: 'Mensaje',
    total: 'Total',
    lists: 'Listados',
    evaluation: 'Evaluación',
    votingEvaluation: 'Evaluación general de los votos',
    evaluations: 'Evaluaciones',
    evaluationsGeneral: 'Evaluacion general',
    evaluationsPerSubject: 'Evaluaciones por temas',
    votingEvaluationsPerSubject: 'Evaluaciones de los votos por temas',
    driveDocument: 'Documento (Drive)',
    linkWeb: 'Link (Web)',
    all: 'Todos',
    unavailable: 'Indisponible',
    active: 'Activo',
    inactive: 'Inactivo',
  },
  description: {
    privacyAndTerms:
      'Cuando usa nuestros servicios, nos confía su información.\n' +
      'Tal vez alguna información presente en esto sito no esté actualizada.\n' +
      'Requisito de edad: 14 años o más.',
    requestChanges: 'Envíanos un mensaje solicitando ajustes. ¡Gracias!',
    searchGuide: 'Intente hacer una búsqueda o filtrar registros.',
    chatAi: 'Link del Chat IA exclusivo (en prueba)',
  },
  alert: {
    signIn: 'Llena el siguiente formulario para acceder a la plataforma.',
    insertCode: 'Por favor, insira abajo el código para verificar su teléfono.',
    receiveCode: 'Para recibir un código se debe resolver el Captcha',
    selectState: 'Por favor, elige un estado.',
  },
  text: {
    dateFormat: 'DD/MM/AAAA',
  },
  select: {
    sex: {
      male: 'Masculino',
      female: 'Femenino',
    },
    theme: {
      light: 'Claro',
      dark: 'Oscuro',
    },
    state: 'Estado',
  },
  button: {
    signIn: 'Acceder',
    signOut: 'Salir',
    language: 'Español',
    confirm: 'Confirmar',
    update: 'Actualizar',
    agree: 'Aceptar',
    disagree: 'Rechazar',
    cancel: 'Cancelar',
    search: 'Buscar',
    filter: 'Filtrar',
    seeList: 'Ver listado',
    seeFilters: 'Ver filtros',
    seeEvaluations: 'Ver evaluaciones',
    close: 'Cerrar',
    chatAi: 'Chat IA (en prueba)',
  },
  hint: {
    account: "Cambia el campo y las opciones de 'comisión'.",
    projectType:
      "Cambia los campos y las opciones de 'posición', 'tipo' y 'leyes'.",
  },
  rule: {
    required: 'Por favor, llene este campo.',
    invalid: 'Valor inválido. Intente otro valor.',
    isNumber: 'Utilize solo números.',
    isPhoneNumber: 'Los números de teléfono deben ser como +5255XXXXXXXX',
    isDate: 'Por favor, llene con una fecha válida.',
    isCode: 'Código no es válido.',
    one: 'Por favor, llene al menos un campo.',
  },
});
