<template>
  <v-row justify="center" align="center" class="fill-height">
    <v-col align="center">
      <v-row justify="center">
        <v-col align="center">
          <v-progress-circular
            color="secondary"
            indeterminate
            size="48"
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'CLoading',
};
</script>
