export default Object.freeze({
  alert: {
    signIn: 'Fill in the following form to access the platform.',
    insertCode: 'Please insert below the code to verify your phone.',
    selectState: 'Please, select a state.',
  },
  text: {
    firstName: 'First name',
    lastName: 'Last name',
    sex: 'Sex',
    birthdate: 'Birthdate',
    state: 'State',
    city: 'City',
    phone: 'Phone',
    code: 'Code',
    email: 'E-mail',
    theme: 'Theme',
    locale: 'Locale',
    dateFormat: 'DD/MM/YYYY',
    required: 'Please fill in this field.',
    isNumber: 'Use numbers only.',
    isPhoneNumber: 'Phone numbers must be like +5255XXXXXXXX',
    isDate: 'Please insert a valid date.',
  },
  select: {
    sex: {
      male: 'Male',
      female: 'Female',
    },
    theme: {
      light: 'Light',
      dark: 'Dark',
    },
  },
  button: {
    signIn: 'Sign in',
    signOut: 'Sign out',
    language: 'English',
    confirm: 'Confirm',
    update: 'Update',
  },
});
