import 'vuetify/styles';
import '@mdi/font/css/materialdesignicons.css';
import { createVuetify } from 'vuetify';
import { es, en, pt } from 'vuetify/locale';
import { md3 } from 'vuetify/blueprints';
import { aliases, mdi } from 'vuetify/iconsets/mdi';
import colors from 'vuetify/lib/util/colors';

export const vuetify = createVuetify({
  locale: {
    messages: { es, en, pt },
    locale: window?.localStorage?.locale || 'es',
    current: window?.localStorage?.locale || 'es',
    name: window?.localStorage?.locale || 'es',
    fallback: 'es',
  },
  blueprint: md3,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
  theme: {
    defaultTheme: window?.localStorage?.theme || 'light',
    variations: {
      colors: ['background'],
      lighten: 1,
      darken: 1,
    },
    themes: {
      light: {
        dark: false,
        colors: {
          primary: colors.lightBlue.lighten4,
          secondary: colors.lightBlue.darken1,
          accent: colors.green.lighten4,
          ready: colors.teal.lighten4,
          readySecondary: colors.teal.darken1,
          background: colors.grey.lighten4,
          ['on-background']: colors.shades.black,
          surface: colors.shades.white,
        },
      },
      dark: {
        dark: true,
        colors: {
          primary: colors.lightBlue.darken4,
          secondary: colors.lightBlue.lighten1,
          accent: colors.green.darken4,
          ready: colors.teal.darken4,
          readySecondary: colors.teal.lighten1,
          background: colors.grey.darken4,
          ['on-background']: colors.shades.white,
          surface: colors.shades.black,
        },
      },
    },
  },
  display: {
    mobileBreakpoint: 'sm',
    thresholds: {
      sm: 0,
      md: 600,
      lg: 960,
      xl: 1280,
      xxl: 1920,
    },
  },
});
