import { formatDateFromSF } from '@/helpers';

export function formatPublication(item) {
  return {
    ...item,
    autor: item.Autor_Reportero__r?.Name,
    date: formatDateFromSF(item.Fecha__c),
  };
}

export function preparePublications(publications = {}) {
  for (let key in publications) {
    publications[key] = formatPublication(publications[key]);
  }
  return publications;
}
