import { defineStore } from 'pinia';
import {
  baseApi,
  retries,
  setBaseApiToken,
  setResponseInterceptor,
} from '@/services/base-api';
import {
  authGetResult,
  authGetToken,
  authSignOut,
} from '@/services/firebase-auth';

const actions = {
  async authenticate() {
    this.loadingAuth = true;
    try {
      this.user = await authGetResult();
    } catch (e) {
      console.error(e);
    }
    try {
      const token = await authGetToken();
      setBaseApiToken(token);
    } catch (e) {
      console.error(e);
    }
    setResponseInterceptor(
      (response) => this.handleTokenExpired({ response }),
      this.handleTokenExpired,
    );
    this.loadingAuth = false;
    return this.user;
  },
  async signOut() {
    await authSignOut();
    this.user = null;
  },
  async handleTokenExpired(error) {
    const { response } = error;
    if (!response) return Promise.reject(error);

    const { status, data } = response;
    if (status < 300) return Promise.resolve(response);
    if (status === 401 && data) {
      const jsonResult = JSON.parse(data);
      if (jsonResult.tokenExpired && retries.attempts < 5) {
        const token = await authGetToken(true);
        const headers = setBaseApiToken(token);
        const config = response.config;
        retries.attempts++;
        return Promise.resolve(
          await baseApi.request({
            ...config,
            headers: { ...config.headers, ...headers },
          }),
        );
      } else if (jsonResult.tokenExpired && retries.attempts >= 5) {
        retries.attempts = 0;
        retries.count++;
        await this.signOut();
      }
    } else if (status === 403) {
      await this.signOut();
    }

    return Promise.reject(error);
  },
};

export const useAutheStore = defineStore('authe', {
  state: () => ({
    user: null,
    loadingAuth: false,
  }),
  getters: {},
  actions,
});
