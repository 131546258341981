const mainUrl = new URL(import.meta.env.VITE_BASE_URL);
if (
  import.meta.env.VITE_BASE_URL === 'https://app.datainves.com' &&
  window?.location?.host &&
  window.location.host !== mainUrl.host
) {
  window.location.replace(mainUrl.href);
}

import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { i18n } from '@/plugins/i18n.js';
import { vuetify } from '@/plugins/vuetify.js';
import { registerChartJS } from '@/plugins/chart.js';
// console.log('vuetify', vuetify);
import router, {
  guardAuthData,
  guardAuthRedirects,
  guardFilters,
  guardRowData,
  clearGuard,
} from '@/router';
import {
  useMainStore,
  useAutheStore,
  useAuthoStore,
  useDbStore,
} from '@/stores';
import { initialize } from '@/services/firebase';

import App from '@/app.vue';

initialize();
const app = createApp(App)
  .use(i18n)
  .use(vuetify)
  .use(createPinia())
  .use(router)
  .mount('#app');

registerChartJS(vuetify);

window.addEventListener('online', () => {
  useMainStore().offLine = false;
});
window.addEventListener('offline', () => {
  useMainStore().offLine = true;
});

for (const guard of [
  guardAuthData({ useMainStore, useAutheStore, useAuthoStore, useDbStore }),
  guardAuthRedirects,
]) {
  router.beforeEach(guard);
}
router.afterEach(guardFilters);
router.afterEach(guardRowData);
router.afterEach(clearGuard);

export default app;
