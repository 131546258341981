<template>
  <v-locale-provider>
    <v-theme-provider>
      <v-app>
        <v-app-bar absolute color="background">
          <v-app-bar-nav-icon
            v-if="
              $route.meta.requiresAuth && $router.options.history.state.back
            "
            @click="goBack"
          >
            <template #default>
              <v-icon size="32" color="secondary"> mdi-arrow-left </v-icon>
            </template>
          </v-app-bar-nav-icon>
          <v-app-bar-nav-icon
            v-if="$route.meta.requiresAuth && $route.name != 'Home'"
            @click="goHome"
          >
            <template #default>
              <v-icon size="32" color="secondary"> mdi-home </v-icon>
            </template>
          </v-app-bar-nav-icon>
          <!-- <v-toolbar-title style="cursor: pointer" @click="goHome">
            <div class="d-flex align-end">
              <v-icon
                v-if="$route.meta.requiresAuth && $route.name != 'Home'"
                class="mr-2"
                color="secondary"
              >
                mdi-home
              </v-icon>
            </div>
          </v-toolbar-title> -->
          <v-spacer></v-spacer>

          <v-menu
            v-if="user && !loadingAuth"
            :close-on-content-click="false"
            :offset="6"
          >
            <template #activator="{ props }">
              <v-card
                v-ripple
                rounded="circle"
                class="mt-1 me-5 cursor-pointer"
              >
                <v-avatar v-bind="props" size="48">
                  <img
                    v-if="user?.photo"
                    :src="user?.photo"
                    :alt="user?.name"
                    width="48"
                    referrer-policy="no-referrer"
                  />
                  <v-icon v-else icon="mdi-account-circle" dark />
                </v-avatar>
              </v-card>
            </template>
            <v-list lines="two">
              <!-- User -->
              <v-list-item>
                <template #default>
                  <v-avatar size="38" class="mx-2">
                    <img
                      v-if="user?.photo"
                      :src="user?.photo"
                      :alt="user?.name"
                      width="38"
                      referrer-policy="no-referrer"
                    />
                    <v-icon v-else icon="mdi-account-circle" dark />
                  </v-avatar>
                  {{ user?.firstName }}
                  {{ user?.lastName?.join(' ') }}
                </template>
              </v-list-item>
              <!-- Theme -->
              <v-list-item>
                <template #default>
                  <v-select
                    class="mx-3"
                    color="secondary"
                    variant="outlined"
                    :prepend-icon="themeIconKey"
                    style="max-width: 150px"
                    :model-value="themeValue"
                    :label="$t('label.theme')"
                    :items="Object.values(themeItems)"
                    :loading="loading"
                    :disabled="loading"
                    single-line
                    hide-details
                    @update:model-value="changeTheme"
                  />
                </template>
              </v-list-item>
              <!-- Locale -->
              <v-list-item v-if="false">
                <template #default>
                  <v-select
                    class="mx-3"
                    color="secondary"
                    variant="underlined"
                    prepend-icon="mdi-translate"
                    style="max-width: 150px"
                    :model-value="localeValue"
                    :label="$t('label.locale')"
                    :items="Object.values(localeItems)"
                    @update:model-value="changeLocale"
                  />
                </template>
              </v-list-item>
              <!-- Chat AI -->
              <a :href="chatAiLink" target="_blank">
                <v-list-item
                  :loading="loadingRole"
                  :disabled="loadingRole"
                  @click="() => {}"
                >
                  <template #default>
                    <v-icon icon="mdi-creation" size="24" class="ms-3 me-4" />
                    {{ $t('button.chatAi') }}
                  </template>
                </v-list-item>
              </a>
              <!-- Logout -->
              <v-list-item
                :loading="loadingRole"
                :disabled="loadingRole"
                @click="leave()"
              >
                <template #default>
                  <v-icon icon="mdi-logout" size="24" class="ms-3 me-4" />
                  {{ $t('button.signOut') }}
                </template>
              </v-list-item>
              <!-- Version -->
              <v-list-item lines="one">
                <template #default>
                  <div class="d-flex">
                    <v-spacer />
                    <div
                      class="ms-3 text-caption font-weight-bold"
                      style="
                        color: rgba(
                          var(--v-theme-on-background),
                          0.3
                        ) !important;
                      "
                    >
                      {{ appVersion }}
                    </div>
                  </div>
                </template>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-app-bar>

        <v-main>
          <loading-view v-if="loading"></loading-view>
          <router-view v-else />
        </v-main>

        <v-bottom-navigation v-if="false" v-model="bottomNav" absolute grow>
          <v-btn value="search">
            <v-icon color="secondary">mdi-magnify</v-icon>
            <span>{{ $t('button.search') }}</span>
          </v-btn>

          <v-btn value="menu">
            <v-icon color="secondary">mdi-menu</v-icon>
            <span>Menu</span>
          </v-btn>
        </v-bottom-navigation>
      </v-app>
    </v-theme-provider>
  </v-locale-provider>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useMainStore, useAutheStore, useAuthoStore } from '@/stores';
import { useChartJS } from '@/plugins/chart.js';

import LoadingView from '@/views/loading.vue';

export default {
  name: 'App',
  components: { LoadingView },
  data: () => ({
    themeValue: window?.localStorage?.theme || 'light',
    localeValue: window?.localStorage?.locale || 'es',
    bottomNav: null,
  }),
  computed: {
    ...mapState(useMainStore, ['isOffline', 'appVersion', 'defaultHomeRoute']),
    ...mapState(useAutheStore, ['user', 'loadingAuth']),
    ...mapState(useAuthoStore, ['loadingRole', 'loadingSignIn']),
    loading() {
      return (
        this.loadingAuth ||
        this.loadingRole ||
        this.loadingSignIn ||
        this.isOffline
      );
    },
    themeItems() {
      return {
        light: { title: this.$t('select.theme.light'), value: 'light' },
        dark: { title: this.$t('select.theme.dark'), value: 'dark' },
      };
    },
    lightThemeIconKey() {
      return 'mdi-weather-sunny';
    },
    darkThemeIconKey() {
      return 'mdi-weather-night';
    },
    themeIconKey() {
      return this.themeValue == 'light'
        ? this.lightThemeIconKey
        : this.darkThemeIconKey;
    },
    localeItems() {
      return {
        es: { title: 'Español', value: 'es' },
        en: { title: 'English', value: 'en' },
        pt: { title: 'Português', value: 'pt' },
      };
    },
    chatAiLink() {
      return import.meta.env.VITE_CHAT_IA_URL || '';
    },
  },
  mounted() {
    this.$vuetify.theme.global.name = this.themeValue;
    this.$vuetify.locale.current = this.localeValue;
    this.$i18n.locale = this.localeValue;
  },
  methods: {
    ...mapActions(useAutheStore, ['signOut']),
    changeTheme(value) {
      this.themeValue = this.themeItems[value];
      this.$vuetify.theme.global.name = value;
      const chartJS = useChartJS();
      chartJS.defaults.color =
        this.$vuetify.theme.current.colors['on-background'];
      window?.localStorage?.setItem('theme', value);
    },
    changeLocale(selected) {
      this.localeValue = this.localeItems[selected];
      this.$vuetify.locale.current = selected;
      this.$i18n.locale = selected;
      window?.localStorage?.setItem('locale', selected);
    },
    async goHome() {
      if (this.$router.currentRoute.name != 'Home') {
        await this.$router.push(this.defaultHomeRoute());
      }
    },
    goBack() {
      useMainStore().goingBack = true;
      this.$router.back();
    },
    async leave() {
      await this.signOut();
      await this.$router.push({ name: 'Login' });
    },
  },
};
</script>

<style>
::-webkit-scrollbar {
  width: 10px;
  height: 12px;
}
::-webkit-scrollbar-track {
  background: #e6e6e6;
  border-left: 1px solid #dadada;
}
::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  border: solid 3px #e6e6e6;
  border-radius: 7px;
}
::-webkit-scrollbar-thumb:hover {
  background: black;
}
/* .app-global-search .v-field {
  font-size: 1.1rem !important;
} */
.app-global-search .v-field__field {
  height: 48px !important;
}
.app-global-search .v-field__input {
  min-height: 48px !important;
  padding: 0px 0px 0px 12px;
}
</style>

<style scoped>
.elevation-1.theme--dark {
  box-shadow:
    0 2px 1px -1px rgba(100, 100, 100, 0.2),
    0 1px 1px 0 rgba(100, 100, 100, 0.14),
    0 1px 3px 0 rgba(100, 100, 100, 0.12) !important;
}
.elevation-2.theme--dark,
.v-btn--is-elevated.theme--dark {
  box-shadow:
    0 3px 1px -2px rgba(100, 100, 100, 0.2),
    0 2px 2px 0 rgba(100, 100, 100, 0.14),
    0 1px 5px 0 rgba(100, 100, 100, 0.12) !important;
}
</style>
