import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/:path(.*)',
    name: 'Loading',
    component: () => import('@/views/loading.vue'),
    meta: { tryToAuth: true },
  },
  {
    path: '/home/:list',
    name: 'Home',
    component: () => import('@/views/home.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login.vue'),
    meta: { tryToAuth: true },
  },
  // {
  //   path: '/signin',
  //   name: 'SignIn',
  //   component: () => import('@/views/sign-in.vue'),
  //   meta: { tryToAuth: true },
  // },
  {
    path: '/row/account/:id',
    name: 'RowAccount',
    component: () => import('@/views/rows/row-account.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/row/politician/:id',
    name: 'RowPolitician',
    component: () => import('@/views/rows/row-politician.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/row/project/:id',
    name: 'RowProject',
    component: () => import('@/views/rows/row-project.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/row/voting/:id',
    name: 'RowVoting',
    component: () => import('@/views/rows/row-voting.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/row/publication/:id',
    name: 'RowPublication',
    component: () => import('@/views/rows/row-publication.vue'),
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export function guardAuthData({
  useMainStore,
  useAutheStore,
  useAuthoStore,
  useDbStore,
}) {
  return async (to) => {
    const mainStore = useMainStore();
    const autheStore = useAutheStore();
    const authoStore = useAuthoStore();
    const dbStore = useDbStore();
    const doAuth = to.meta.requiresAuth || to.meta.tryToAuth;
    if (doAuth) {
      if (!autheStore.user) {
        await autheStore.authenticate();
      }
      if (autheStore.user) {
        if (!authoStore.role) {
          await authoStore.getRole();
        }
        // if (
        //   autheStore.user?.phone &&
        //   autheStore.user?.state &&
        //   authoStore.role === 'desconocido'
        // ) {
        //   await authoStore.ingresar();
        // }
      }
    }
    to.meta.mainStore = mainStore;
    to.meta.dbStore = dbStore;
    to.meta.doAuth = doAuth;
    to.meta.user = autheStore.user;
    to.meta.phoneAndState = autheStore.user?.phone && autheStore.user?.state;
    to.meta.role = authoStore.role;
  };
}

export const guardAuthRedirects = async ({ name, meta }, _, next) => {
  const doAuth = meta.doAuth;
  const user = meta.user;
  const phoneAndState = meta.phoneAndState;
  // const role = meta.role;

  const toLogin = name != 'Login' && doAuth && !user;

  // const toSignIn =
  //   name != 'SignIn' &&
  //   doAuth &&
  //   user &&
  //   (!phoneAndState || role === 'desconocido');

  const toHome =
    ['Login', 'SignIn', 'Loading'].includes(name) && user && phoneAndState;

  if (toLogin) {
    next({ name: 'Login' });
    // } else if (toSignIn) {
    //   next({ name: 'SignIn' });
  } else if (toHome) {
    next({ name: 'Home', params: { list: 'politicians' } });
  } else {
    next();
  }
  if (name != 'Home') window.scrollTo({ top: 0, behavior: 'smooth' });
};

export const guardFilters = async ({ meta, query }) => {
  const mainStore = meta.mainStore;
  if (!mainStore) {
    return;
  }
  if (query.filters) {
    mainStore.filters = JSON.parse(query.filters || '{}');
  } else {
    mainStore.filters = {};
  }
};

export const guardRowData = async () => {};
// export const guardRowData = async ({ name, meta, params }) => {
//   const dbStore = meta.dbStore;
//   if (!dbStore) {
//     return;
//   }
//   if (
//     name in
//       [
//         'RowAccount',
//         'RowPolitician',
//         'RowProject',
//         'RowVoting',
//         'RowPublication',
//       ] &&
//     params.id
//   ) {
//     if (name === 'RowAccount') {
//       await dbStore.getAccount(params.id);
//     } else if (name === 'RowPolitician') {
//       await dbStore.getPolitician(params.id);
//     } else if (name === 'RowProject') {
//       await dbStore.getProject(params.id);
//     } else if (name === 'RowVoting') {
//       await dbStore.getVoting(params.id);
//     } else if (name === 'RowPublication') {
//       await dbStore.getPublication(params.id);
//     }
//   }
// };

export const clearGuard = async ({ meta }) => {
  // Important to clear meta in the last guard
  delete meta.mainStore;
  delete meta.dbStore;
  delete meta.doAuth;
  delete meta.user;
  delete meta.phoneAndState;
  delete meta.role;
};

export default router;
