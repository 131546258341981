import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getDatabase, connectDatabaseEmulator } from 'firebase/database';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';

const config = {
  apiKey: 'AIzaSyBsYIhJhZ4jGNLSb-W536p0Jd1_9WhtxoU',
  authDomain: 'externos-3b016.firebaseapp.com',
  projectId: 'externos-3b016',
  storageBucket: 'externos-3b016.appspot.com',
  messagingSenderId: '812802068665',
  appId: '1:812802068665:web:6e62238c9d04545a4d8b68',
};

export function initialize() {
  const authDomain = import.meta.env.VITE_AUTH_DOMAIN;
  const app = initializeApp({ ...config, authDomain });
  if (
    location?.hostname === 'localhost' &&
    import.meta.env.MODE === 'testing'
  ) {
    const firestoreURL = import.meta.env.VITE_FIRESTORE_URL;
    const databaseURL = import.meta.env.VITE_DATABASE_URL;
    connectAuthEmulator(getAuth(), authDomain);
    connectDatabaseEmulator(getDatabase(), ...databaseURL.split(':'));
    connectFirestoreEmulator(getFirestore(), ...firestoreURL.split(':'));
  }
  return app;
}
