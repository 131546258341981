import { defineStore } from 'pinia';
import { formatPolitician } from '@/controllers/politician.js';
import { formatProject } from '@/controllers/project.js';
import { formatVoting } from '@/controllers/voting.js';
import { formatPublication } from '@/controllers/publicacion.js';
import { useAutheStore } from '@/stores/authentication.js';
import { useAuthoStore } from '@/stores/authorization.js';
import { useDbStore } from '@/stores/db.js';

const actions = {};

const useMainStore = defineStore('main', {
  state: () => ({
    state: null,
    lists: [
      {
        viewName: 'ListPoliticians',
        plural_key: 'politicians',
        plural_icon: 'mdi-account-multiple',
      },
      {
        viewName: 'ListAccounts',
        plural_key: 'accounts',
        plural_icon: 'mdi-office-building',
      },
      {
        viewName: 'ListProjects',
        plural_key: 'projects',
        plural_icon: 'mdi-file-document-multiple',
      },
      {
        viewName: 'ListVotings',
        plural_key: 'votings',
        plural_icon: 'mdi-archive',
      },
      {
        viewName: 'ListPublications',
        plural_key: 'publications',
        plural_icon: 'mdi-newspaper-variant-multiple',
      },
    ],
    offLine: window && !window.navigator?.onLine,
    appVersion: import.meta.env.VITE_APP_VERSION,
    goingBack: false,
    globalSearch: '',
  }),
  getters: {
    defaultHomeRoute() {
      return (list = 'politicians') => ({
        name: 'Home',
        params: { list },
        query: { filters: '{}' },
      });
    },
    isOffline: (state) => state.offLine,
  },
  actions,
});

export { formatPolitician, formatProject, formatVoting, formatPublication };
export { useMainStore, useAutheStore, useAuthoStore, useDbStore };
