import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export function registerChartJS(vuetify) {
  ChartJS.register(
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
    ChartDataLabels,
  );

  const currentTheme = vuetify.theme.current.value;
  const vuetifyColors = currentTheme.colors;
  const modifier = currentTheme.dark ? 'lighten' : 'darken';
  ChartJS.defaults.color = vuetifyColors['on-background'];
  ChartJS.defaults.borderColor = vuetifyColors[`background-${modifier}-1`];
  ChartJS.defaults.font.size = 16;
  ChartJS.defaults.locale = vuetify.locale.current;

  return ChartJS;
}

export const useChartJS = () => {
  return ChartJS;
};
