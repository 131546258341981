import mxStates from '@/helpers/mxStates.json';
import mxCitiesByState from '@/helpers/mxCitiesByState.json';

export { mxStates, mxCitiesByState };

export const formatDateFromSF = (day) => {
  const dayText = (day || '00/00/00 00:00+0000').replace(/\+[\d]+/, '+0600');
  if (!dayText.includes('+0600')) {
    const timezoneOffset = /GMT(.*?)\s/.exec(new Date().toTimeString())[1];
    return new Date(`${dayText} 00:00${timezoneOffset}`);
  }
  return new Date(`${dayText}`); // Returns invalid date if day is undefined or null
};

export function formatDate(date) {
  if (!date) return null;
  return `${date.getFullYear()}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
}

export function formatDateDisplay(date) {
  if (date?.toString() === 'Invalid Date') return '';
  const result =
    date?.toLocaleDateString?.(window?.navigator?.language || 'pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }) ||
    formatDate(date) ||
    '';
  return result;
}
