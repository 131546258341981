import { formatDateFromSF } from '@/helpers';

export function formatPolitician(item) {
  if (!item) return {};
  let partido = '',
    abrev_partido = '',
    sepPartido = 0;
  let tipo_de_eleccion;
  if (item.Curriculum__r?.Partido__c) {
    partido = item.Curriculum__r.Partido__r.Name;
    abrev_partido = item.Curriculum__r.Partido__r.Name;
    sepPartido = abrev_partido.indexOf(' ');
  }

  let suplente = item.Curriculum__r?.Electo_por__c == 'Suplente';
  if (item.Mandato__r?.Representacion__c) {
    tipo_de_eleccion =
      item.Mandato__r.Representacion__c + (suplente ? ' (Suplente)' : '');
  } else {
    tipo_de_eleccion = '';
  }

  const index = item.Contacto__c;

  const state =
    item.Mandato__r?.Estado__c ||
    item.Curriculum__r?.Estado__c ||
    item.Contacto__r?.Estado__c;

  const cargo = item.Cargo__r?.Name;

  const mainPost = item.Cargo_principal__c;

  return {
    ...item,
    index,
    nombre: item.Contacto__r?.Name,
    partido,
    abrev_partido:
      sepPartido > 0 ? abrev_partido.slice(0, sepPartido) : abrev_partido,
    sexo: item.Contacto__r?.Genero__c,
    tipo_de_eleccion,
    distrito_circunscripcion: item.Mandato__r?.Distrito_Circunscripcion__c,
    state,
    cargo,
    cadeira: { ...item },
    contacto: { ...item.Contacto__r },
    dateInicial: formatDateFromSF(item.Fecha_inicial__c),
    dateFinal: formatDateFromSF(item.Fecha_final__c),
    mainPost,
  };
}

export function preparePoliticians(table = {}) {
  const politicians = table.politicos;
  const evaluations = table.evaluaciones || {};
  const values = {};
  for (const key in politicians) {
    const item = formatPolitician(politicians[key]);
    const index = item.Contacto__c;
    let total = 0;
    item.evaluations = {};
    for (const type in evaluations) {
      const evaluationsType = evaluations[type]?.[index] || {};
      item.evaluations[type] = evaluationsType;
      for (const kind in evaluationsType) {
        const value = evaluationsType[kind];
        total += value;
      }
      item.totalEvaluation = total;
    }
    values[index] = item;
  }
  return values;
}
